"use-client";

import { Analytics, AnalyticsBrowser, Context } from "@segment/analytics-next";
import { SESSION_NAVIGATION } from "@src/constants/constant";
import { useLazyGetUserQuery } from "@src/services/users";
import { selectAuthUser } from "@src/store/slices/login/selectors";
import { User } from "@src/store/slices/login/types";
import { useRouter } from "next/router";
import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";

import { useIdleMonitor } from "@src/hooks/useIdleMonitor";
import { useSession } from "@src/hooks/useSession";
import { useWindowName } from "@src/hooks/useWindowName";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

const SegmentContext = createContext(null);

interface SegmentProviderProps {
  children: ReactNode;
}

export const SegmentProvider: React.FC<SegmentProviderProps> = ({ children }) => {
  const idleState = useIdleMonitor(1800000);
  const [segment, setSegment] = useState(null);
  const [isSegmentLoaded, setIsSegmentLoaded] = useState(false);
  const [isIdle, setIsIdle] = useState(true);
  const dispatch = useAppDispatch();

  const { pathname } = useRouter();
  const locationUser = useRef({ latitude: null, longitude: null });
  const pathnameRef = useRef(pathname);
  const { windowName, updateWindowName } = useWindowName();
  const [userId, setUserId] = useState("");

  const windowNameRef = useRef(windowName);

  useEffect(() => {
    pathnameRef.current = pathname;
  }, [pathname]);

  useEffect(() => {
    setIsIdle(idleState);
  }, [idleState]);

  useEffect(() => {
    windowNameRef.current = windowName;
  }, [windowName]);

  useEffect(() => {
    const getUserIdFromLocalStorage = () => {
      const userTraits = localStorage.getItem("ajs_user_traits");
      if (userTraits) {
        try {
          const parsedTraits = JSON.parse(userTraits);
          return parsedTraits.solutionshub_id || "";
        } catch (error) {
          console.error("Erro ao analisar ajs_user_traits:", error);
        }
      }
      return "";
    };

    const idFromLocalStorage = getUserIdFromLocalStorage();
    setUserId(idFromLocalStorage);
  }, []);

  useEffect(() => {
    const fetchClientInfos = async () => {
      try {
        const position = await getUserLocation();
        locationUser.current = {
          latitude: position.coords.latitude.toString(),
          longitude: position.coords.longitude.toString(),
        };
      } catch (error) {
        console.error("Failed to fetch client information:", error);
      }
    };

    fetchClientInfos();
  }, [dispatch]);

  const getUserLocation = (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const loadSegment = async () => {
    return new Promise(resolve => {
      if (!isSegmentLoaded) {
        AnalyticsBrowser.load({
          writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        }).then(([response]: [Analytics, Context]) => {
          setSegment(response);
          setIsSegmentLoaded(true);
          resolve(response);
        });
      }
    });
  };

  useEffect(() => {
    if (!segment && !isSegmentLoaded) {
      loadSegment();
    }
  }, [segment, isSegmentLoaded]);

  useEffect(() => {
    if (!isSegmentLoaded || !segment || !userId) return;

    const trackEvent = () => {
      if (!pathnameRef.current.includes("/settings") && isIdle) {
        segment.track(
          SESSION_NAVIGATION,
          {
            solutionshub_id: userId,
            path: pathnameRef.current,
            sessionId: windowNameRef.current,
            sessionStart: sessionStorage.getItem("sessionStart"),
          },
          {
            context: {
              location: {
                latitude: locationUser.current.latitude,
                longitude: locationUser.current.longitude,
              },
            },
          },
        );
      }
    };

    trackEvent();
    const trackInterval = setInterval(trackEvent, 30000);

    return () => clearInterval(trackInterval);
  }, [isSegmentLoaded, segment, isIdle]);

  return (
    <SegmentContext.Provider value={{ segment, locationUser: locationUser.current }}>
      {children}
    </SegmentContext.Provider>
  );
};

export const useSegment = () => {
  return useContext(SegmentContext);
};
