import { createApi } from "@reduxjs/toolkit/query/react";
import { AppDispatch } from "@src/store";
import { tasksActions } from "@src/store/slices/task";
import { ChangeStatus } from "@src/types/changeStatus";

import { api, baseQueryMiddleware } from "./api";

interface IParamGetTAsks {
  limit?: number;
  offset?: number;
}

export const getTasks =
  (param?: IParamGetTAsks) =>
  async (dispatch: AppDispatch): Promise<any> => {
    const { limit, offset } = param || { limit: 8, offset: 0 };
    try {
      dispatch(tasksActions.controlView({ isLoading: true, hasError: false }));
      const { data, status } = await api.get("/tasks", {
        params: { limit, offset },
      });

      dispatch(
        tasksActions.controlData({
          data,
        }),
      );

      dispatch(tasksActions.controlView({ isLoading: false, hasError: false }));
      return data;
    } catch (error) {
      dispatch(tasksActions.controlView({ isLoading: false, hasError: true }));
    }
  };

export const countTasks =
  (status: string) =>
  async (dispatch: AppDispatch): Promise<any> => {
    try {
      dispatch(tasksActions.controlView({ isLoading: true, hasError: false }));
      const { data, status: responseStatus } = await api.get(`/list/${status}`);

      dispatch(
        tasksActions.controlData({
          data,
        }),
      );

      dispatch(tasksActions.controlView({ isLoading: false, hasError: false }));
      return data;
    } catch (error) {
      dispatch(tasksActions.controlView({ isLoading: false, hasError: true }));
    }
  };

export const getTaskChangeLog =
  (issueId: string) =>
  async (dispatch: AppDispatch): Promise<any> => {
    try {
      dispatch(tasksActions.controlView({ isLoading: true, hasError: false }));
      const { data, status: responseStatus } = await api.get(
        `/workflow/tasks/${issueId}/changelog`,
      );

      dispatch(
        tasksActions.controlData({
          data,
        }),
      );

      dispatch(tasksActions.controlView({ isLoading: false, hasError: false }));
      return data;
    } catch (error) {
      dispatch(tasksActions.controlView({ isLoading: false, hasError: true }));
    }
  };

export const taskApi = createApi({
  reducerPath: "taskApi",
  baseQuery: baseQueryMiddleware,
  endpoints: builder => ({
    geTasks: builder.query({
      query: () => ({
        url: "/",
        method: "GET",
      }),
    }),
    getTask: builder.query({
      query: ({ issueId }) => ({
        url: `/tasks/${issueId}`,
        method: "GET",
      }),
    }),
    getTasksFeedback: builder.query({
      query: params => ({
        url: `/workflow/issues/rejectioncomment`,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const createChangeStatus =
  (body: ChangeStatus | string[]) => async (dispatch: AppDispatch) => {
    try {
      dispatch(tasksActions.controlView({ isLoading: true, hasError: false }));
      const { data, status } = await api.post(`/workflow/issues/changestatus`, body);
      if (status == 200 || status == 201 || status == 204) {
        dispatch(tasksActions.controlView({ isLoading: false, hasError: false }));
        return true;
      }
    } catch (err: any) {
      dispatch(tasksActions.controlView({ isLoading: false, hasError: true }));
      throw err;
    }
  };

export const downloadTaskContent =
  (param?: string) =>
  async (dispatch: AppDispatch): Promise<any> => {
    const issueId = param;
    try {
      dispatch(tasksActions.controlView({ isLoading: true, hasError: false }));
      const { data, status, headers } = await api.get("/workflow/issues/downloadcontent", {
        params: { issueId },
        responseType: "arraybuffer",
      });

      if (status == 200 || status == 201 || status == 204) {
        dispatch(tasksActions.controlView({ isLoading: false, hasError: false }));

        const blob = new Blob([data], {
          type: headers["content-type"] || headers["Content-Type"],
        });

        const filename = headers["content-filename"] || headers["Content-Filename"];

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = decodeURIComponent(filename);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);

        return data;
      }
    } catch (error) {
      dispatch(tasksActions.controlView({ isLoading: false, hasError: true }));
    }
  };

export const GetApprovalHistory =
  (issueId: string) =>
  async (dispatch: AppDispatch): Promise<any> => {
    try {
      dispatch(tasksActions.controlView({ isLoading: true, hasError: false }));
      const { data, status: responseStatus } = await api.get(`/workflow/tasks/${issueId}/status`);

      dispatch(
        tasksActions.controlData({
          data,
        }),
      );

      dispatch(tasksActions.controlView({ isLoading: false, hasError: false }));
      return data;
    } catch (error) {
      dispatch(tasksActions.controlView({ isLoading: false, hasError: true }));
    }
  };

export const uploadTaskContent = async (file: File, issueId?: string): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await api.post("/workflow/tasks/uploadcontent", formData, {
      params: { issueId },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

export const { useGetTaskQuery, useLazyGetTaskQuery, useGeTasksQuery, useGetTasksFeedbackQuery } =
  taskApi;
