import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

import { useWindowName } from "./useWindowName";

export const useIdleMonitor = (timer: number) => {
  const [active, setActive] = useState(true);
  const { updateWindowName } = useWindowName();

  const onIdle = () => {
    setActive(false);
    updateWindowName();
  };
  const onActive = () => {
    setActive(true);
  };

  useIdleTimer({
    onIdle,
    onActive,
    timeout: timer,
    throttle: 500,
  });

  return active;
};
