import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

export const useWindowName = () => {
  const [windowName, setWindowName] = useState<string | null>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!window.name) {
        const newUuid = uuidv4();
        window.name = newUuid;
      }

      setWindowName(window.name);

      const handleNameChange = () => setWindowName(window.name);

      window.addEventListener("nameChange", handleNameChange);

      return () => {
        window.removeEventListener("nameChange", handleNameChange);
      };
    }
  }, []);

  const updateWindowName = () => {
    const newUuid = uuidv4();
    window.name = newUuid;
    setWindowName(newUuid);
    sessionStorage.setItem("sessionStart", new Date().toISOString());
    window.dispatchEvent(new Event("nameChange"));
  };

  return { windowName, updateWindowName };
};
